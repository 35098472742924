//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-846:_6600,_4832,_9920,_1598,_886,_4320,_9938,_2200;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-846')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-846', "_6600,_4832,_9920,_1598,_886,_4320,_9938,_2200");
        }
      }catch (ex) {
        console.error(ex);
      }