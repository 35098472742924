import shopifyEmpireGenericDefaults from '../shopify-empire-generic/config.js';

const baseAfterInit = shopifyEmpireGenericDefaults.AfterInit;

shopifyEmpireGenericDefaults.Widgets.forEach((w) => {
  if (w.name === 'FacetPanel' || w.type === 'FacetPanel') {
    if (w.ignoreFields) {
      w.ignoreFields.push('Family');
    } else {
      w.ignoreFields = ['Family'];
    }
  }
});

function AfterInit() {
  baseAfterInit?.();
  if (
    window.ShopifyAnalytics?.meta.page.pageType === 'page' &&
    !['/pages/search', '/pages/brands'].includes(window.location.pathname)
  ) {
    window.Convermax.discardVehicle();
  }
}

export default {
  ...shopifyEmpireGenericDefaults,
  includes: ['shopify-empire-generic'],
  AfterInit,
  Widgets: [
    ...shopifyEmpireGenericDefaults.Widgets.filter((w) => !['SearchHeader'].includes(w.name)),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        '_popularity:desc': 'Best Selling',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, low to high',
        'price:desc': 'Price, high to low',
      },
    },
  ],
};
