import shopifyEmpireGenericDefaults from '../shopify-empire-generic/config.js';

const baseAfterInit = shopifyEmpireGenericDefaults.AfterInit;

shopifyEmpireGenericDefaults.Widgets.forEach((w) => {
  if (w.name === 'FacetPanel' || w.type === 'FacetPanel') {
    if (w.ignoreFields) {
      w.ignoreFields.push('Family');
    } else {
      w.ignoreFields = ['Family'];
    }
  }
});

function AfterInit() {
  baseAfterInit?.();
  if (
    window.ShopifyAnalytics?.meta.page.pageType === 'page' &&
    !['/pages/search', '/pages/brands'].includes(window.location.pathname)
  ) {
    window.Convermax.discardVehicle();
  }
}

window.Convermax.isMegaBucksEnabled = (vendor) => {
  return !['SOUNDSTREAM', 'PRECISION POWER', 'HERTZ'].includes(vendor.toUpperCase());
};

export default {
  ...shopifyEmpireGenericDefaults,
  includes: ['shopify-empire-generic'],
  AfterInit,
  getSearchRequestDefaults: (pageType) => (pageType === 'category' ? { sort: 'order_count:desc' } : {}),
};
